import _ from 'lodash'
import { navigate } from 'gatsby'

export default class Window {
  /**
        Allow any part of the app to signal to the user using the `notistack`.
        Relies on an `alertFn`, set at runtime in `src/pages/page.js`.
    */
  static alert(msg, args = {}) {
    args.autoHideDuration = 5000
    if (_.isFunction(Window.alertFn)) Window.alertFn(msg, args)
    else if (typeof window !== 'undefined')
      // eslint-disable-next-line no-alert
      window.alert(msg)
  }

  static confirm(msg) {
    if (_.isFunction(Window.confirmFn)) Window.confirmFn(msg)
    else if (typeof window !== 'undefined')
      Promise((resolve, reject) => {
        // eslint-disable-next-line no-alert
        if (window.confirm(msg)) resolve(true)
        reject(false)
      })
  }

  static fn(...path) {
    if (typeof window === 'undefined') return () => {}
    return _.get(window, ...path)
  }

  static get(...path) {
    if (typeof window === 'undefined') return null
    if (_.isEmpty(path)) return null
    return _.get(window, ...path)
  }

  static navigate(...args) {
    if (typeof window === 'undefined') return null
    navigate(...args)

    const event = 'navigate'
    if (_.isFunction(window.sa_event)) {
      window.sa_event(event)
    } else {
      console.log(`Could not log: ${event}`)
    }
  }

  static prompt(msg, currentValue) {
    if (_.isFunction(Window.promptFn)) Window.promptFn(msg)
    else if (typeof window !== 'undefined')
      Promise((resolve, reject) => {
        // eslint-disable-next-line no-alert
        const response = window.prompt(msg, currentValue)
        if (response) resolve(response)
        reject(null)
      })
  }

  static WIP() {
    Window.alert("I'm still working on this. Come back later!", {
      variant: 'warning',
      preventDuplicate: true,
    })
  }
}
